// Core variables and mixins
@import "res/scss/__variables.scss";
@import "res/scss/__mixins.scss";

.main-solution-wrap {
  background-image:url(/views/res/imgs/main/img_main_strength.jpg);
  background-repeat: no-repeat;
  background-position:center;
  background-size: cover;
  color:#fff;
  @include media-query-min(lg){
    padding: 163px 0 160px;
    html:not([class*='ie']) & {
      background-attachment: fixed;
    }
  }
  .flex-items-center {
    @include media-query-min(md){
      display:flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .desc-wrap{
    @include media-query-min(md){
      flex: 1 1 0%;
    }
  }
  .title {
    font-size:30px;
    font-weight:700;
    letter-spacing: -0.06em;
    @include media-query-min(md){
      font-size:40px;
    }
  }
  .sub-title {
    line-height:1.5;
    padding:17px 0 22px;
    letter-spacing: -0.025em;
    font-size:20px;
    @include media-query-min(md){
      padding:14px 0 30px;
      font-size:22px;
    }
  }
  .desc {
    line-height:1.75;
    font-size:16px;
    letter-spacing: -0.02em;
    color:#ddd;
    @include media-query-min(lg){
      line-height: 1.875;
    }
  }
  .main-strength-img {
    text-align: center;
    margin-top:50px;
    @include media-query-min(md){
      width:300px;
      margin-top:0;
      text-align:right;
    }
    @include media-query-min(lg){
      width: 375px;
    }
    .mobile {
      max-width:375px;
      width: 100%;
    }
  }
}