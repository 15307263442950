/////default/////
//color
$g_white: #fff !default;
$g_black: #000 !default;
$g_red: #ff0000;

$g_point-font-color: #fff;
$g_point-theme-color: #29a3b8;
$g_point-theme2-color: #38c0d6;


//font
//-font-family
$g_font-family: (
  system: ' "맑은 고딕", "Malgun Gothic", "애플 SD 산돌고딕 Neo", "Apple SD Gothic Neo", sans-serif ',
  // noto: '"Noto Sans KR", sans-serif',
  nanumsqure: '"NANUMSQUARE", sans-serif',
  dotum: '"Dotum", sans-serif',
  lato: '"Lato", sans-serif',
);
$font-system: #{map-get($g_font-family, system)};
// $font-noto: #{map-get($g_font-family, noto)};
$font-nanumsqure: #{map-get($g_font-family, nanumsqure)};
$font-dotum: #{map-get($g_font-family, dotum)};
$font-lato: #{map-get($g_font-family, lato)};

//-font-size
$g_font-size:(
  xs: 10px,
  sm: 12px,
  md: 15px,
  lg: 18px,
  xl: 24px,
);

//-font-weight
$g_font-weights: 100, 200, 300, 400, 500, 600, 700, 800, 900;

//-letter-spacing

/////media/////
$g_media-breakpoints: (
  xs: 0,
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

/////layout/////
//area
$g_max-width: 1140px;
$g_area-gutter-width: 15px;

//grid
$g_grid-columns: 12;
$g_grid-gutter-width: 1%;

$g_default-area: (
  max-width: $g_max-width,
  gutter-width: $g_area-gutter-width,
  width: 100%
);
$g_full-area: (
  max-width: 1750px,
  gutter-width: $g_area-gutter-width,
  width: 100%
);
$g_nogutter-area: (
  max-width: $g_max-width
);
$g_btn-area: (
  max-width: $g_max-width,
  gutter-width: 20px,
);

//grid
$g_grid-col-info: (
  grid-col-cnt: 12,
  grid-col-name: null
);
$grid-col5-info: (
  grid-col-cnt: 5,
  grid-col-name: five-
);

/////UTILITIES/////
//clear

//spacing
$spacers:(
  0: 0,
  5: 5px,
  10: 10px,
  15: 15px,
  20: 20px,
  25: 25px,
  30: 30px,
  35: 35px,
  40: 40px,
  45: 45px,
  50: 50px,
  55: 55px,
  60: 60px,
  65: 65px,
  70: 70px,
  75: 75px,
  80: 80px,
  85: 85px,
  90: 90px,
  100: 100px,
  140: 140px
);

//===================================================================================================================//

/////COMPONENTS/////
//card
$g_card-wrap: (
  default: (
    wrap: (
      padding: 0 30px,
      background-color: #fff,
      margin-bottom: 30px,
      border-style: ( border: "1px solid #e7e9ee" ),
      border-radius: 5px
    ),
    header: (
      padding: 25px 0,
      background-color: #fff,
      border-style: ( border-bottom: "1px solid #e7e9ee" ),
      font-size: 16px
    ),
    body: (
      padding: 30px 0,
      background-color: #fff
    ),
    footer: (
      padding: 25px 0,
      background-color: #fff,
      border-style: ( border-top: "1px solid #e7e9ee" )
    )
  )
);
//_form-warp
$g_form-wrap: (
  default_value: (
    background-color: #fff,
    vertical-align: middle,
  ),
  table : (
    media: (
      xs: (
        form-wrap: (//.form-warp-table .form-wrap
          display: table,
          width: 100%,
          border-top: "1px solid #eee",
          "&" : (
            ":last-child" : (
              border-bottom: "1px solid #eee"
            )
          )
        ),
        form-title: (//.form-warp-table .form-title
          display: table-cell,
          width: 100px,
          padding: "0 10px",
          background-color: #f8f8f8,
          vertical-align: middle,
          font-size: 12px,
        ),
        form-body: (//.form-warp-table .form-body
          display: table-cell,
          padding: "10px 15px",
          vertical-align: middle,
          font-size: 14px,
        ),
      ),
      md: (
        form-title: (
          width: 150px,
          padding: "20px 20px",
          font-size: 14px,
        ),
        form-body: (
          padding: "10px 15px"
        ),
      ),
    ),
  ),
  material : (
    media: (
      xs: (
        form-wrap: (
          display: block,
          width: 100%,
          "&" : (
            ":after" : (
              content: "''",
              display: block,
              width: 100%,
              height: 1px,
              transition: "transform .5s, background-color .5s",
              transform: scaleX(0),
              background-color: #333,
            ),
            ":hover:after" : (
              background-color: red,
              transform: scaleX(1),
            ),
            ".active:after" : (
              transform: scaleX(1),
            )
          ),
        ),
        form-title: (
          display: block,
          font-size: 12px,
        ),
        form-body: (
          display: block,
        ),
      ),
    ),
  ),
  no-media-test : (
    xs :(
      form-wrap: (
        display: block,
        width: 100%,
      ),
    ),
  ),
);
//_input
$g_field-style: (
  style1: (//관리자
    border-style: (border-bottom: 1px solid #d5d6d8),
    input-height: auto,
    input-padding: 0 0 5px 0,
    input-bg: transparent,
    placeholder-color: #cccfd6,
  ),
  style2: (//회원가입, 회원정보 수정 폼
    max-width: 330px,
    border-style: (border: 1px solid #ddd),
    input-height: 35px,
    input-padding: 0 10px,
    placeholder-color: #cccfd6,
    font-size: 13px,
    font-color: #555,
    letter-spacing: 0.05em,
  ),
  style3: (//로그인, 회원가입 플로우 //수정완료
    border-style: (border: 1px solid #ddd),
    input-height: 50px,
    input-padding: 0 10px,
    input-bg: transparent,
    placeholder-color: #909090,
    font-size: 12px,
    font-color: #333,
    letter-spacing: 0.05em,
  ),
  style4: (//상품 상세
    border-style: (border: 1px solid #d2d2d2),
    input-height: 42px,
    input-padding: 0 15px,
    input-bg: #fff,
    placeholder-color: #909090,
    font-size: 13px,
    font-color: #6e6d6d,
    letter-spacing: -0.08em,
  )
);

//_textarea
$g_text-area-style: (
  style1: (
    text-area-height: 150px,
    text-area-padding: 5px,
  ),
  style2: (
    text-area-height: 150px,
    text-area-padding: 10px,
  ),
  style3: (
    text-area-height: 150px,
    text-area-padding: 5px,
  ),
  
);

//_select
$g_select-style: (
  style1: (
    background-position: right 100px bottom 50%,
      background-image: url(/views/res/imgs/common/btn_select_arrow_st01.png),
  ),
  style2: ( // (회원가입,회원정보) 연락처
    padding: 0 35px 0 10px,
    background-position: right 10px bottom 50%,
    background-image: url(/views/res/imgs/common/btn_select_arrow_st01.png),
    //option : ( color:#222 ) //서브 클래스 생성
  ),
  style3: (
    overflow: hidden,
  ),
  style4: (//상품 상세
    background-position: 100% 50%,
    background-image: url(/views/res/imgs/common/img_select_style1.png),
    //option : ( color:#222 ) //서브 클래스 생성
  )
);
//_check-box
$g_check-box-img: (
  img-1 : (
    width: 24px,
    height: 24px,
    margin-right: 5px,
    box-img: url(/views/res/imgs/page/img_checkbox03_off.png),
    box-img-on: url(/views/res/imgs/page/img_checkbox03_on.png),
    font-size: 14px
  ),
  img-2 : (
    width: 32px,
    height: 32px,
    margin-right: 8px,
    box-img: url(/views/res/imgs/page/img_checkbox01_off.png),
    box-img-on: url(/views/res/imgs/page/img_checkbox01_on.png),
    font-size: 14px
  ),
  img-3 : (
    width: 20px,
    height: 20px,
    box-img: url(/views/res/imgs/page/img_checkbox02_off.png),
    box-img-on: url(/views/res/imgs/page/img_checkbox02_on.png),
    font-size: 14px
  )
);
$g_check-box-tag: (
  tag-1 : ( // 아이디저장 // 약관동의 // 결제수단 //배송지 선택 // 배송지역 선택
    width: 14px,
    height: 14px,
    margin-right: 10px,
    box-margin-right : 5px,
    box-outline: 1px solid #aaa,
    checked-color: #555,
    font-size: 11px
  ),
  tag-2 : (
    width: 20px,
    height: 20px,
    box-margin-right: 5px,
    box-outline: 1px solid #333,
    checked-width: 12px,
    checked-height: 12px,
    checked-color: #333,
    font-size: 14px
  )
);

//_table
$g_rp-table: (
  style1: (
    media: (
      xs: (
        rp-table: (
          margin-top: 50px,
          border-top: 1px solid #e7e9ee
        ),
        rp-thead: (
          display: none
        ),
        rp-th: (

        ),
        rp-tr: (
          display: block,
          padding: 10px 5px,
          border-bottom: 1px solid #e7e9ee,
          transition: background-color .5s,
          "&" : (
            ":hover" : (
              background-color: #f7f7f7,
            ),
            ".is-notice" : (
              background-color: #f7f7f7,
            ),
            ".none-hover:hover" : (
              background-color: #fff,
            ),
          ),
          
        ),
        rp-td: (
          display: inline-block,
          padding: 5px,
          background-color: transparent,
          font-size: 14px,
          letter-spacing: -0.05em,
          color: #606369,
          "&" : (
            ".td-block" : (
              display : block
            )
          )
        ),
      ),
      md: (
        rp-thead: (
          display: table,
          width: 100%,
          border-bottom: 1px solid #e7e9ee,
          padding: 0 20px,
        ),
        rp-th: (
          display: table-cell,
          padding: 22px 5px,
          background-color: #fff,
          font-size: 14px,
          letter-spacing: -0.05em,
          color: #a1a5b1,
        ),
        rp-tr: (
          display: table,
          width: 100%,
          padding: 0 20px,
          table-layout: fixed,
        ),
        rp-td: (
          display: table-cell,
          width: 100%,
          padding: 20px 5px,
          vertical-align: middle,
          "&" : (
            ".td-block" : (
              display : table-cell
            )
          )
        ),
      )
    ),
  )
);

//_button
$g_btn-size: (
  default_value: (), //추가시 base/_buttons.scss 수정요망
  xs: ( //배송조회,후기작성
    width: 50px,
    height: 35px,
    line-height: 35px,
    font-size: 12px,
  ),
  sm: ( //게시판 버튼, popup
    width: 118px,
    height: 50px,
    line-height: 50px,
    font-size: 14px,
    media: (
      sm: (
        height: 40px,
        line-height: 40px,
      )
    )
  ),
  md: ( //우편번호찾기, 회원탈퇴
    width: 100px,
    height: 35px,
    line-height: 35px,
    font-size: 14px,
  ),
  lg: ( // 기본 사이즈,제출하기 버튼
    width: 100%,
    height: 60px,
    line-height: 60px,
    font-size: 17px,
    font-weight: 700,
    media: (
      sm: (
        width: 235px,
      )
    )
  ),
  xl: ( // 사용안함
    width: 246px,
    height: 49px,
    line-height: 49px,
    font-size: 16px,
  ),
  padding: (
    padding: 10px 20px,
    font-size: 13px,
    inner-test: (
      width: 100%,
      height: 50px,
      test: (
        size: 10px
      )
    ),
    media:(
      sm:(
        opacity:1
      )
    ),
  ),
  full:(
    width: 100%,
    height: 60px,
    line-height: 60px,
    font-size: 17px,
    font-weight: 700,
  ),
);

$g_btn-group : ( // 버튼 스타일 설정하기
  // 버튼클래스이름 : 사용할 속성값 (사용하지 않을경우 변수 삭제가능, 변수 추가할경우 mixin_buttons 파일에 또한 추가)
  point :  ( // default
    theme-color: $g_point-theme2-color,
    font-color: #fff !important,
    border : transparent,
    // border-radius:5px,
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.3),
    hover-theme-color:$g_point-theme-color,
    // 기본적으로 darken이 들어가며, 호버효과가 없는것은 배경색(theme-color)와 동일한 컬러값넣어준다.
     hover-color: #fff,
    // hover-border : 1px solid #000,
    disabled-group :( // 기본적으로 opacity:.65, 커스텀은 아래에 값 변경
      dim-theme-color:#111,
      dim-color: #111,
      dim-opacity : .5,
    ),
  ),
  sub-point :(
    theme-color:#fff,
    font-color: $g_point-theme-color,
    hover-theme-color:$g_point-theme2-color,
    hover-color: #fff !important,
    hover-text-shadow: 0 0 4px rgba(0, 0, 0, 0.3),
  ),
  style1 :  ( //로그인
    theme-color: #aaa,
    font-color: #fff,
  ),
  style2 : ( // 회원가입, 비밀번호 아이디찾기,
    font-color: #000,
    theme-color: #f2f2f2,
    border:1px solid #ddd,
    hover-theme-color:#f2f2f2,
  ),
  style3 : ( // 주문 취소 버튼, 주문 리스트 검색 버튼
    font-color: #fff,
    theme-color: #555,
  ),
  style4 : ( // 제출하기 버튼
    font-color: #fff,
    theme-color: #333,
    hover-theme-color: #333,
    hover-color:#fff,
  ),
  outline-style1 :  ( // 주문상세보기, custom2
    font-color: #111,
    theme-color: transparent,
    border : 1px solid #ddd,
    hover-border : 1px solid #ddd,
  ),
  outline-style2 : ( // 시작하기, 무료로사용하기 버튼
    font-color: #fff,
    theme-color: transparent,
    border : 1px solid #fff,
    hover-border : 1px solid #fff,
    hover-theme-color: #fff,
    hover-color: $g_point-theme-color,

  ),
  outline-style3 : ( // 소개서다운로드, 가입하기 버튼
    font-color: #fff,
    theme-color: $g_point-theme-color,
    border : 1px solid transparent,
    hover-border : 1px solid transparent,
    hover-text-shadow: 0 0 4px rgba(0, 0, 0, 0.3),
  ),
  outline-style4 : ( // [Pricing] 도입문의하기 버튼
    font-color: $g_point-theme-color,
    theme-color: transparent,
    border : 1px solid $g_point-theme-color,
    hover-border : 1px solid $g_point-theme2-color,
    hover-theme-color: $g_point-theme2-color,
    hover-color: #fff,
    hover-text-shadow: 0 0 4px rgba(0, 0, 0, 0.3),
  ),
  black : (
    theme-color: #000,
    font-color: #fff,
  )
);

//_pagination (명칭 : a 태그 page-link 로(스타일)  / page-item 이 각자의 wrap (간격조정)/ 전체를 .pagination이 감싸고 있음)
$g_page-item-group : (
  // default
  width:50px,
  height:50px,

  //padding:20px,
  margin:0 -1px,
  theme-color: #fff,
  border : 1px solid #ddd,
  //border-radius: 3px,
  //border-radius-f-l: 3px, // 첫번째 마지막 버튼 코너 radius
  //border-right : none, // 버튼이 붙어있는 경우
  //border-style: ( // last-child에 적용됨
  //	border-right : "1px solid #e1e1e1",
  //),

  font-size:16px,
  font-weight:400,
  letter-spacing:-0.05em,
  font-color:#777,

  // hover
  hover-theme-color:#fff,
  // hover-border-color : #ddd,
  hover-color: #000,

  // active
  active-theme:#f2f2f2,
  active-color:#000,
  active-style: ( // 커스텀용
    //border-bottom:"2px solid #02388b",
    //font-weight:bold,
  ),

  // disabled
  dim-opacity : .5,
  //disabled-style: ( // 커스텀용
  //	//버튼에서는 map-has-key값을 이용해서 키값이 잇으면 맵의 키값과 벨류로 출력
  //	//페이지네이션에서는 키값이 있으면 안에 내용 그대로 출력..
  //	//결과 값은 같으나 mixin방법이 다르다 현재
  //	background-color:#111,
  //	color: #111,
  //	border-bottom:"2px solid #02388b",
  //),
  arrow-group : (
    start : ( //커스텀
      width: "100%",
      height: "100%",
      background-image :url(/views/res/imgs/common/pagination_arrow_start.png),
      background-position:center,
      background-repeat:no-repeat,
    ),
    prev : (
      width: "100%",
      height: "100%",
      background-image :url(/views/res/imgs/common/pagination_arrow_prev.png),
      background-position:center,
      background-repeat:no-repeat,
    ),
    next : (
      width: "100%",
      height: "100%",
      background-image :url(/views/res/imgs/common/pagination_arrow_next.png),
      background-position:center,
      background-repeat:no-repeat,
    ),
    end : (
      width: "100%",
      height: "100%",
      background-image :url(/views/res/imgs/common/pagination_arrow_end.png),
      background-position:center,
      background-repeat:no-repeat,
    ),
  ),
);

//_tooltip
$g_tooltip-group : ( // .tooltip > .tooltip-inner
  top : -40px,
  padding: 3px 8px,
  background-color:#000,
  border-radius:3px,
  font-size:13px,
  letter-spacing:-0.05em,
  color:#fff,
  text-align:center,
  transition-time : .5s,
  opacity:0, //normal
  show-opacity:.9, //:hover일때
  arrow-group : ( // 툴팁꼬리
  //  left : 0,
  //  right : 0,
    center: 50%,
    arrow-width:5px 5px 0,
    border-top-color: #000,
  ),
);

//_tab
$g_tab-group : ( // .tab-wrap > .tab-list.on
  // 아래에 공통부분 sub-classing 기준으로 안에서 상속받는걸로 나눠지는 이슈체크
  //float:left,
  display:inline-block,
  width:150px,
  padding:9px 20px,
  theme-color: #fff,
  font-color: #000,
  font-weight:300,
  font-size:14px,
  text-align:center,
  letter-spacing:-0.02em,

  sub-classing : ( // 입력하는데로
    style : ( // 네모탭
      margin:5px,
      border:1px solid #000,
    ),
    style1 : ( // 일렬탭
      border-bottom: 1px solid #e1e1e1,
    ),
  ),

  active : ( // 입력하는데로
    border-color: $g_point-theme-color,
    background-color:$g_point-theme-color,
    color:#fff,
  ),

  //미디어쿼리
  media: (
    md: ( // 입력하는데로
      //display:block,
      //width:100%,
      //font-size:12px
    )
  )
);
//_searchbar

//_ card

