// Button variants
// 버튼 사이즈별 생성
@mixin button-size-px($btn-size : $g_btn-size) {
  @each $key, $val in ($btn-size){
    @if ($key == default_value) {
      %default_value {
        @each $key2, $val2 in $val {
          #{$key2}: #{$val2};
        }
      }
    } @else {
      .btn-#{$key} {
        @each $key2, $val2 in $val {
          @if ($key2 == media) {
            @each $key3, $val3 in $val2 {
              @include media-query-min($key3) {
                $codes: makeMapToCodes($val3);
                @include printCodes($codes);
              }
            }
          } @else{
            @if ( type-of($val2) == map ) { // one depth class 안에 추가 class 생성
              .#{$key2} {
                $codes: makeMapToCodes($val2);
                @include printCodes($codes);
              }
            } @else { // one depth class 버튼
              #{$key2}: #{$val2};
            }
          }
        }
      }
    }
  }
}

@mixin btn-disabled() {
  &.disabled {
    @content;
    pointer-events:none;
  }
}

// 버튼 스타일 설정해주기
@mixin button-style($btn-group : $g_btn-group){
  @each $key, $val in $btn-group {
    .btn-#{$key},
    .btn-#{$key}:visited {
      background-color: map-get($val, theme-color);
      color: map-get($val, font-color);
      border: map-get($val, border);
      border-radius : map-get($val, border-radius);
      text-shadow : map-get($val, text-shadow);
      @include media-query-min(lg){
        @include hover-on {
          @if map-has-key($val, hover-theme-color) {
              background-color:map-get($val, hover-theme-color);
          }
          @else {
            background-color:darken( map-get($val, theme-color), 15%);
          }
          @if map-has-key($val, hover-color) {
            color:map-get($val, hover-color);
          }
          @else {
            color:map-get($val, font-color);
          }
          border: map-get($val, hover-border);
          text-shadow: map-get($val, hover-text-shadow);
        }
      }
      @include btn-disabled {
        @if map-has-key($val, disabled-group) {
          background-color: map-get(map-get($val, disabled-group),dim-theme-color);
          color: map-get(map-get($val, disabled-group), dim-color);
          opacity: map-get(map-get($val, disabled-group), dim-opacity);
        }
        @else {
          opacity:.65;
        }
      }
    }
  }
}
